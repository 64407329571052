.search-bar-container {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.search-bar-title {
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: 400px) {
        display: none !important;
    }
}

.search-bar-button {
    @media screen and (max-width: 400px) {
        display: none !important;
    }
    flex-grow: 1;
    background-color: $theme-color-1819-primary;
    vertical-align: center;
    border-radius: 50px;
    border: none;
    font-weight: bold;
    color: white;
    display: flex;
    padding: 5px 20px;
    font-size: 20px;

    &.small {
        display: none;
        padding: 3px 7px;
        right: 7px;
        font-size: 17px;
    }
}
.search-bar-button-below {
    @media screen and (min-width: 401px) {
        display: none !important;
    }
    background-color: $theme-color-1819-primary;
    width: 100%;
    margin-top: 10px;
    justify-content: center;
    flex-grow: 1;
    border: none;
    vertical-align: center;
    border-radius: 50px;
    font-weight: bold;
    color: white;

    display: flex;
    padding: 5px 20px;
    right: 10px;
    font-size: 20px;

    &.small {
        display: none;
        padding: 3px 7px;
        right: 7px;
        font-size: 17px;
    }
}

.search-bar-input {
    outline-width: 0;
    font-size: 25px;
    padding-right: 100px;
    padding-left: 20px;
    height: 55px;

    border-radius: 30px;
    border-style: solid;
    border-width: 1px;
    width: 100%;

    flex-grow: 1;
    justify-content: center;
    align-self: center;
    vertical-align: center;
    box-shadow: 0px 4px 20px 0px #00000022;

    @media screen and (max-width: 400px) {
        padding-right: 20px;
    }
    &.small {
        font-size: 20px;
        padding-right: 15px;
        padding-left: 15px;
        height: 40px;
    }
}
