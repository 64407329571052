.simkerfi-phone-controller {
    .transfer-number {
        font-size: 23px;
    }

    .send-sms-check input {
        height: 20px;
        width: 20px;
    }

    .send-sms-check span {
        font-size: 13px;
        line-height: 13px;
        margin-top: 4px;
        font-weight: 600;
    }

    .call-details-container {
        margin: 0 5px 10px 5px;
        .call-details-queue-name {
            font-weight: 600;
            margin: 0px;
            padding: 0px;
            font-size: 16px;
            line-height: 16px;
            min-height: 16px;
            vertical-align: center;
        }
        .call-details-phone {
            font-size: 20px;
            line-height: 20px;
            min-height: 20px;
            vertical-align: center;
        }
    }
}

.simkerfi-script {
    width: 0px;
    padding: 0px;
    transition: 0.3s ease-in-out;
    &.simkerfi-script-visible {
        width: 100%;
        padding: 20px;
        transition: 0.3s ease-in-out;
    }
}

.simkerfi-iframe {
    border: none;
    width: 100%;
    transition: 0.3s ease-in-out;

    &.simkerfi-script-visible {
        width: 500px;
        transition: 0.3s ease-in-out;
        border-left: 1px solid #e6e6e6;
    }
}

#company-email-form {
    label {
        display: block;
        margin-top: 15px;
        font-weight: 600;
    }
    textarea {
        min-height: 200px;
    }
    h5 {
        font-weight: 600;
    }
}
