.simsvorun-company-table {
    width: 100%;

    thead {
        td,
        th {
            border-bottom: 3px solid #e0e0e0;
            padding: 0.7rem 1rem;
        }
    }
    td {
        padding: 0.7rem 1rem;
    }

    tbody {
        tr {
            border-bottom: 1px solid #e0e0e0;
            &:last-child {
                border-bottom: none;
            }
            &:nth-child(odd) {
                background: rgba($theme-color-1819-primary, 0.07);
            }
        }
    }
    &.selectable {
        tbody {
            tr {
                &:hover {
                    background-color: rgba($theme-color-1819-primary, 0.13);
                    transition: 0.3s;
                }
                &:active {
                    background-color: rgba($theme-color-1819-primary, 0.4);
                }
            }
        }
    }
    &.compact {
        thead {
            td,
            th {
                padding: 0.3rem 1rem;
            }
        }
        tbody {
            tr {
                td {
                    padding: 0.3rem 1rem;
                }
            }
        }
    }
}
